import React from "react";
import { useState } from "react";
import logo from "logo.png";
import { Link } from "react-router-dom";

function NavigationBar(props) {
  const { title, darkMode, setDark } = props;

  const [isNavBarOpen, setNavBarOpen] = useState(false);

  const toggleNavBar = () => {
    setNavBarOpen(!isNavBarOpen);
  };

  return (
    <div>
      <nav className="p-12">
        <div className="flex justify-between items-center ">
          <div className="flex justify-center items-center">
            <img src={logo} alt="logo" className="w-6" />
            <h1 className="font-bold p-4 ">
              <Link
                to="/"
                className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white"
              >
                {title}
              </Link>
            </h1>
          </div>
          <div className="flex justify-between items-center pr-6">
            <div>
              {/* Desktop NavBar */}
              <nav className="hidden md:flex gap-5 ">
                <Link
                  to="/"
                  className="pl-4 text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="pl-4 md:pl-12 text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                >
                  About
                </Link>
                <a
                  href={props.blogURL}
                  className="pl-4 md:pl-12 text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Blog
                </a>
              </nav>

              {/* Hamburger Component */}
              {isNavBarOpen ? (
                // Close Button
                <i
                  className="fixed right-[30px] z-50 md:hidden"
                  aria-hidden="true"
                  onClick={toggleNavBar}
                >
                  <svg
                    className="h-8 w-8 text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </i>
              ) : (
                // Hamburger Button
                <i
                  className="text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white md:hidden"
                  aria-hidden="true"
                  onClick={toggleNavBar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 17h14M5 12h14M5 7h14"
                      ></path>
                    </g>
                  </svg>
                </i>
              )}

              {/* Mobile NavBar */}
              <nav
                className={`h-[100vh] fixed top-[0px] flex flex-col justify-around items-center w-full md:hidden bg-white dark:bg-gray-900 z-40 duration-1000
                ${isNavBarOpen ? "right-[0px]" : "right-[-100vw]"} `}
              >
                <Link
                  to="/"
                  onClick={toggleNavBar}
                  className="text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  onClick={toggleNavBar}
                  className="text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                >
                  About
                </Link>
                <a
                  href={props.blogURL}
                  className="text-gray-600 hover:text-black font-semibold dark:text-gray-300 dark:hover:text-white"
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={toggleNavBar}
                >
                  Blog
                </a>
                <span></span>
                <span></span>
              </nav>
            </div>

            <div className="p-4 md:pl-12">
              <i
                onClick={() => setDark()}
                className="text-gray-700 hover:text-black cursor-pointer dark:text-gray-300 dark:hover:text-white"
              >
                {!darkMode && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    />
                  </svg>
                )}

                {darkMode && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                )}
              </i>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavigationBar;
