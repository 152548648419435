import React from "react";

function Footer(props) {
  let year = new Date().getFullYear();

  return (
    <div>
      <div className="p-20">
        <div>
          <h3 className="flex justify-center items-center font-semibold text-gray-700 dark:text-gray-300">
            Want to reach out?
          </h3>
        </div>
        <div className="flex justify-center items-center">
          {props.github && (
            <a
              href={`https://github.com/${props.github}`}
              className="p-4 m-4 rounded-full text-blue-500 bg-blue-100 hover:text-blue-100 hover:bg-blue-400 dark:bg-gray-700 dark:text-blue-00 dark:hover:bg-blue-500 dark:hover:text-blue-100"
            >
              <svg
                className="w-6"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 00-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0020 4.77 5.07 5.07 0 0019.91 1S18.73.65 16 2.48a13.38 13.38 0 00-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 005 4.77a5.44 5.44 0 00-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 009 18.13V22"></path>
              </svg>
            </a>
          )}
          {props.linkedin && (
            <a
              href={`https://www.linkedin.com/in/${props.linkedin}`}
              className="p-4 m-4 rounded-full text-blue-500 bg-blue-100 hover:text-blue-100 hover:bg-blue-400 dark:bg-gray-700 dark:text-blue-00 dark:hover:bg-blue-500 dark:hover:text-blue-100"
            >
              <svg
                className="w-6"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6z"></path>
                <path d="M2 9H6V21H2z"></path>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          )}
          {props.twitter && (
            <a
              href={`https://twitter.com/${props.twitter}`}
              className="p-4 m-4 rounded-full text-blue-500 bg-blue-100 hover:text-blue-100 hover:bg-blue-400 dark:bg-gray-700 dark:text-blue-00 dark:hover:bg-blue-500 dark:hover:text-blue-100"
            >
              <svg
                className="w-6"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
          )}
          {props.email && (
            <a
              href={`mailto:${props.email}`}
              className="p-4 m-4 rounded-full text-blue-500 bg-blue-100 hover:text-blue-100 hover:bg-blue-400 dark:bg-gray-700 dark:text-blue-00 dark:hover:bg-blue-500 dark:hover:text-blue-100"
            >
              <svg
                className="w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </a>
          )}
        </div>
        <div className="flex flex-col justify-center items-center text-gray-600 text-sm md:text-base dark:text-gray-400">
          <i className="text-black font-bold dark:text-white">
            Older version can be found{" "}
            <a
              className="text-blue-600 font-extrabold dark:text-blue-500"
              href="https://old.mrinjamul.in"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
          </i>
          <i>Copyright © 2018-{year} | All rights reserved</i>
          <i>
            Made with <span className="font-bold not-italic">❤️</span> and{" "}
            <span className="font-bold not-italic">🍺</span>
            {props.dev && ` by ${props.dev}`}.
          </i>
        </div>
      </div>
    </div>
  );
}

export default Footer;
