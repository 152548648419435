import React, { useState, lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavigationBar from "components/NavigationBar";
import Footer from "components/Footer";
import Loading from "components/Home/Loading";

// Lazy import components
const Home = lazy(() => import("components/Home"));
const About = lazy(() => import("components/About"));
const NotFound = lazy(() => import("components/404"));
const underMaintenance = lazy(() => import("components/underMaintenance"));

function App() {
  // enable or disable dark mode
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("theme"))
  );
  const setDark = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("theme", JSON.stringify(!darkMode));
  };

  // user info
  const [user] = useState({
    username: "mrinjamul",
    firstName: "Injamul",
    middleName: "Mohammad",
    lastName: "Mollah",
    role: "Software Developer",
    interest: [
      "Full-Stack Web Development",
      "Software Development",
      "DevOps Engineering",
    ],
    underMaintenance: false,
  });

  let underDev = "";
  // underDev =
  //   user.underMaintenance === true ? " bg-red-500 dark:bg-red-900" : "";

  return (
    <Router>
      <div className={`${darkMode ? "dark" : ""}`}>
        <div
          className={"w-full min-h-screen bg-white dark:bg-gray-900" + underDev}
        >
          <NavigationBar
            title={`@${user.username}`}
            blogURL={"https://mrinjamul.is-a.dev/blog"}
            setDark={setDark}
            darkMode={darkMode}
          />
          <Suspense
            fallback={
              <div className="flex flex-col justify-center items-center text-lg">
                <Loading />
              </div>
            }
          >
            <Switch>
              {user.underMaintenance && <Route component={underMaintenance} />}
              <Route exact path="/" render={() => <Home user={user} />} />
              <Route exact path="/about" component={About} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
          <Footer
            github={user.username}
            linkedin={user.username}
            twitter={user.username}
            email={`${user.username}@gmail.com`}
            dev={`${user.firstName} ${user.middleName} ${user.lastName}`}
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
